import React from 'react'

import MainImage from '../../assets/ast/ast-02.png'
import Instructions from '../../assets/pizza-image.jpg'

const DocBox = () => {
    return (
        <div>
            <div className="new-container">
                <div className="main-doc-box-container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="migration-div mobile">
                                <h3>Our Mission</h3>
                            </div>
                            <div className="instructions-image">
                                <img src={Instructions} alt="" className="img-responsive" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="migration-div desktop">
                                <h3>Our Mission</h3>
                            </div>
                            {/* <div className="antronut">
                                <img src={MainImage} alt="" />
                            </div> */}
                            <div className="instruction-div">
                                <h3>
                                    PizzaNFT has a simple mission. Create PIZZA DOUGH ASTROS and other characters for DIGITAL static and dynamic art; all characters created will be characters in 2D, 3D and VR games and videos. Host NFT Marketplace for minting new NFTS on the Binance Smart Chain.
                                </h3>
                                <a href="https://pizza-nft.com/PizzaNFTWhitePaper.pdf" target="_blank">White Paper</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocBox
