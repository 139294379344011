import React from 'react'
import Coin from '../../assets/icons/coin.svg'
import Dex from '../../assets/icons/dex.png'
import Geko from '../../assets/icons/geko.svg'

const SocialBar = () => {
    return (
        <>
            <div className="social-bar-cont">
                <div className="social-links">
                    <a target="_blank" href="https://www.facebook.com/PizzaNFT/" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-facebook-f"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/pizzanftofficial/" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-instagram"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://twitter.com/pizzaNFT2E" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-twitter"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://t.me/pizzanft" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-telegram-plane"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://discord.gg/pKsHdWUgU4" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-discord"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://www.tiktok.com/@pizzaNFT" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-tiktok"></i>
                        </span>
                    </a>

                    <a target="_blank" href="https://www.youtube.com/channel/UCDlGQA5bzWvL994MdGLTu_g" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-youtube"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://www.reddit.com/r/pizzanft?utm_medium=android_app&utm_source=share" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-reddit-alien"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/pizzanft/mycompany" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-linkedin-in"></i>
                        </span>
                    </a>
                    {/* <a target="_blank" href="#" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-medium-m"></i>
                        </span>
                    </a> */}
                    <a target="_blank" href="https://poocoin.app/tokens/0xb07905396A419B121213efe1d17cfD0ff20aE597" className="social-icon">
                        <span className="social-cell">
                            <i class="fas fa-poo"></i>
                        </span>
                    </a>

                    <a target="_blank" href="https://poocoin.app/tokens/0xb07905396A419B121213efe1d17cfD0ff20aE597" className="social-icon">
                        <span className="social-cell">
                            <i class="fas fa-chart-line"></i>
                        </span>
                    </a>

                    <a target="_blank" href="https://dex.guru/token/0xb07905396A419B121213efe1d17cfD0ff20aE597-bschttps://dex.guru/token/0xb07905396A419B121213efe1d17cfD0ff20aE597-bsc" className="social-icon">
                        <span className="social-cell">
                            <img src={Dex} alt="" />
                        </span>
                    </a>

                    <a target="_blank" href="https://coinmarketcap.com/currencies/pizzanft/" className="social-icon">
                        <span className="social-cell">
                            <img src={Coin} alt="" />
                        </span>
                    </a>

                    <a target="_blank" href="https://www.coingecko.com/en/coins/pizzanft" className="social-icon">
                        <span className="social-cell">
                            <img src={Geko} alt="" />
                        </span>
                    </a>
                </div>
            </div>
        </>
    )
}

export default SocialBar
