import React from 'react'
import Contact from '../../assets/contact.svg'

const ContactUs = () => {
    return (
        <>
            <div className="container" id="contactus">
                <div className="row">
                    <div className="col-md-12">
                        <div className="contact-header">
                            <h2 className="contact-header">Contact</h2>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="contact-emails">
                            <h3><a href="mailto:info@pizza-nft.com">info@pizza-nft.com</a></h3>
                            <h3><a href="mailto:admin@pizza-nft.com">admin@pizza-nft.com</a></h3>
                            <h3><a href="mailto:techsupport@pizza-nft.com">techsupport@pizza-nft.com</a></h3>
                            <h3><a href="mailto:nft@pizza-nft.com">nft@pizza-nft.com</a></h3>
                            <h3><a href="mailto:PR@pizza-nft.com">PR@pizza-nft.com</a></h3>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="contact-items">
                            <div className="row">

                                <form>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" id="inputName" placeholder="What is your Name"></input>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input type="email" class="form-control" id="inputEmail" placeholder="What is your Email"></input>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <textarea name="your-messave" id="" cols="30" rows="5" placeholder="Your Message"></textarea>
                                        <button type="submit" class="btn btn-primary">Send</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs
