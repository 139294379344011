import React, { Fragment } from 'react'
import { Timeline, Event } from "react-timeline-scribble";
import logotext from '../../assets/logo-text.png'

const TimeLine = () => {
    return (
        <div className="main-road-map" id="roadmap">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                        <Fragment>
                            <img src={logotext} alt="" className="img-responsive" />
                            <h2>ROADMAP</h2>
                            <Timeline>
                                <Event interval={"Q4 2021"}>
                                    <ul className="road-ul">
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >SafePizza rebranded as Pizza NFT</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >
                                            Pizza NFT Contract, Rewards in BNB
                                        </li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Token Name: PIZZANFT</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Token Symbol: $PIZZA</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Total Supply 150,000,000,000 (150 Billion)</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Burn 50,000,000,000 (40 Billion)</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >LP Locked (Portions)</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Retained Rated #1 Marketing and PR firm name LUNA PR and Marketing Firm www.LunaPR.io</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >PIZZA NFT WEBSITE</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >PIZZA NFT AUDIT – CERTIK and others</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >PIZZA NFT Marketplace</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >PIZZA NFT ECOSYSTEM</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >PIZZA NFT TRACKER  tracker.pizza-nft.com</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >New Social Links</li>
                                        <ul className="sub-ul"
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >
                                            <li><a href="https://www.twitter.com/PizzaNFT2E">Twitter.com/PizzaNFT2E</a></li>
                                            <li><a href="https://www.facebook.com/PizzaNFT">Facebook:  www.facebook.com/PizzaNFT</a></li>
                                            <li><a href="https://T.me/PIZZANFT">Telegram:  T.me/PIZZANFT</a></li>
                                            <li><a href="https://www.youtube.com/channel/UCDlGQA5bzWvL994MdGLTu_g/">Youtube: www.youtube.com/channel/UCDlGQA5bzWvL994MdGLTu_g</a></li>
                                            <li><a href="https://medium.com/@PizzaNFT">Medium:  https://medium.com/@PizzaNFT</a></li>
                                            <li><a href="https://www.instagram.com/pizzanftofficial">Instagram:  www.instagram.com/pizzanftofficial/</a></li>
                                            <li><a href="https://Reddit: www.reddit.com/r/pizzanft?utm_medium=android_app&utm_source=share">Reddit: www.reddit.com/r/pizzanft?utm_medium=android_app&utm_source=share</a></li>
                                            <li><a href="https://discord.gg/pKsHdWUgU4">Discord:  https://discord.gg/pKsHdWUgU4</a></li>
                                        </ul>

                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Designing NFT Collections</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >LAUNCH ANNOUNCEMENT</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >LAUNCH EVENT & AIRDROPS</li>
                                        <ul className="sub-ul"
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >
                                            <li>Logo on Trust Wallet</li>
                                            <li>Logo in BSCscan</li>
                                            <li>Submit to CoinMarketCap</li>
                                            <li>Submit to CoinGecko</li>
                                            <li>SafePizza Charity Foundation- Non-Profit will still continue to do Food Distribution to the poor and expand to more cities and other countries outside of India.</li>
                                        </ul>
                                    </ul>
                                </Event>
                                <Event interval={"Q1 2022"}>
                                    <ul className="road-ul">
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >NFT Marketplace</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >NFT Portal</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Monthly production of Mini Arcade Games</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Listing on Exchanges TBD</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Press Release </li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Marketing Campaigns | NFTs weekly GiveAways</li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >PizzaMerchants.com </li>
                                        <li
                                            data-aos="fade-up"
                                            data-aos-delay="30"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out-cubic"
                                        >Universal Wallet Tracker</li>


                                        <li>Video AMA</li>
                                        <li>Crypto Conferences</li>

                                    </ul>
                                </Event>
                            </Timeline>
                        </Fragment>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeLine
