import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'

import Khann from '../assets/team/khann.jpg'
import Jazy from '../assets/team/jazy.jpg'
import David from '../assets/team/david.jpg'
import Courage from '../assets/team/courage.jpg'
import Kim from '../assets/team/kim.jpg'
import Mk from '../assets/team/mk.jpg'
import Kuriawan from '../assets/team/kuriawan.jpg'
import Frank from '../assets/team/frank.jpg'
import Hazz from '../assets/team/hazz.jpg'
import Nero from '../assets/team/nero.jpg'
import Mialn from '../assets/team/mialn.jpg'





const Team = () => {

    return (
        <>
            <SocialLinks />
            <NavBar />
            <div className="container">
                <div className="team-main">
                    <div className="team-sub">
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Khann} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="https://www.linkedin.com/in/safepizzaceo/" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Jazy} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="https://www.linkedin.com/in/jazy-sl-3948b5216/" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={David} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="https://www.linkedin.com/in/david-m-569485216/" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Courage} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="#" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Kim} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="https://www.linkedin.com/in/nga-nguyen-020857198/" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Mk} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="https://www.linkedin.com/in/mk-mannan-a15863126/" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Kuriawan} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="#" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Frank} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="#" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Hazz} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="#" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Nero} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="#" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                <img src={Mialn} alt="" className="img-responsive" />
                            </div>
                            <a target="_blank" href="#" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Team
