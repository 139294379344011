import React from 'react'

import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'
import SocialBar from '../components/socialbar/SocialBar'
import MarketPlace from '../components/marketplace/MarketPlace'




const Market = () => {
    return (
        <>
            <SocialLinks />
            <NavBar />
            <SocialBar />
            <MarketPlace />
            <Footer />
            <ScrollTop />
        </>
    )
}

export default Market
