import React from 'react'
import AOS from "aos";

import AtomicHub from '../../assets/marketicon/atomichub-logo.png'
import BlockParty from '../../assets/marketicon/blockparty-logo.png'
import CargoNfts from '../../assets/marketicon/cargo-nfts.png'
import EnjinLogo from '../../assets/marketicon/enjin-logo.png'
import KnownOrigin from '../../assets/marketicon/known-origin-logo.png'
import MakersPlace from '../../assets/marketicon/makers-place-logo.png'
import MintBase from '../../assets/marketicon/mintbase-logo.png'
import NiftyGateway from '../../assets/marketicon/niftygateway-logo.png'
import Opensea from '../../assets/marketicon/opensea-nft-logo.png'
import Rarible from '../../assets/marketicon/rarible-logo.png'
import SimpleMarket from '../../assets/marketicon/SIMPLEMARKET-LOGO.png'
import Superrare from '../../assets/marketicon/superrare-logo.png'



const MarketPlace = () => {
    return (
        <>
            <div className="market-container">
                <div className="container">
                    <div className="market-main-row">
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub atomic">
                                <img src={AtomicHub} alt="AtomicHub" />
                                <h3>AtomicHub</h3>
                                <h4>Wax Gaming & Art</h4>
                                <a href="https://app.airnfts.com/creators/0x0336c67BF56634eFE9e02318396B297de1DB42c4" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub blockparty">
                                <img src={BlockParty} alt="BlockParty" />
                                <h3>Blockparty</h3>
                                <h4>CryptoArt</h4>
                                <a href="https://blockparty.co/" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub cargonfts">
                                <img src={CargoNfts} alt="CargoNfts" />
                                <h3>Cargo</h3>
                                <h4>CryptoArt</h4>
                                <a href="https://app.cargo.build/creator/0x0336c67BF56634eFE9e02318396B297de1DB42c4" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub enjinlogo">
                                <img src={EnjinLogo} alt="EnjinLogo" />
                                <h3>Enjin</h3>
                                <h4>ENJ Gaming</h4>
                                <a href="https://enjinx.io/eth/project/100e010a-077c-4574-a9ec-700f72a5a968" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                    </div>
                    <div className="market-main-row">
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub knownorigin">
                                <img src={KnownOrigin} alt="KnownOrigin" />
                                <h3>Known Origin</h3>
                                <h4>CryptoArt</h4>
                                <a href="https://knownorigin.io/profile/0x0336c67bf56634efe9e02318396b297de1db42c4" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub makersplace">
                                <img src={MakersPlace} alt="MakersPlace" />
                                <h3>MakersPlace</h3>
                                <h4>CryptoArt</h4>
                                <a href="https://makersplace.com/pizzanft/" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub mintbase">
                                <img src={MintBase} alt="MintBase" />
                                <h3>Mintbase</h3>
                                <h4>CryptoArt</h4>
                                <a href="https://mintable.app/u/pizzanft" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub niftygateway">
                                <img src={NiftyGateway} alt="NiftyGateway" />
                                <h3>Nifty Gateway</h3>
                                <h4>CryptoArt</h4>
                                <a href="https://niftygateway.com/profile/pizzanft" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                    </div>
                    <div className="market-main-row">
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub opensea">
                                <img src={Opensea} alt="Opensea" />
                                <h3>Opensea</h3>
                                <h4>NFT Gaming & CryptoArt</h4>
                                <a href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/1453718174941493401824506535668832966822479363848202181370511228863642599425/" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub rarible">
                                <img src={Rarible} alt="Rarible" />
                                <h3>Rarible</h3>
                                <h4>CryptoArt</h4>
                                <a href="https://rarible.com/pizzanft" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub simplemarket">
                                <img src={SimpleMarket} alt="SimpleMarket" />
                                <h3>SimpleMarket</h3>
                                <h4>Wax Gaming & Art</h4>
                                <a href="https://wax.simplemarket.io/" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                        <div className="market-box"
                            data-aos="fade-right"
                            data-aos-delay="10"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out-cubic">
                            <div className="market-box-sub superrare">
                                <img src={Superrare} alt="Superrare" />
                                <h3>SuperRare</h3>
                                <h4>CryptoArt</h4>
                                <a href="https://superrare.com/pizzanft" target="_blank">BUY & SELL NFTS </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketPlace
