import React from 'react'

const ButtonSet = () => {
    return (
        <>
            <div className="container">
                <div className="youtube-video">
                    <div className="main-video">
                        <iframe width="100%" height="400" src="https://www.youtube.com/watch?v=yONALgUmAiY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="button-set">
                    <a href="http://games.pizza-nft.com/" target="_blank">Games</a>
                    <a href="https://poocoin.app/tokens/0xb07905396A419B121213efe1d17cfD0ff20aE597" target="_blank">Check BSC SCAN</a>
                    <a href="#" target="_blank">How To Buy Guide</a>
                    <a href="http://tracker.pizza-nft.com/" target="_blank">Wallet Tracker</a>
                    <a href="#" target="_blank">International TG</a>
                    <a href="http://charity.safe-pizza.com/" target="_blank">Charity</a>
                </div>
            </div>
        </>
    )
}

export default ButtonSet
