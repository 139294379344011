import React from 'react'
import Logo from '../../assets/logo.jpg'
import Coin from '../../assets/icons/coin.svg'
import Dex from '../../assets/icons/dex.svg'
import Geko from '../../assets/icons/geko.svg'

const NavBar = () => {
    return (
        <div className="main-nav-bar">
            <div className="container">
                <div className="nav-container">
                    <div className="nav-bar-items">
                        <ul class="nav navbar-nav">
                            <li><a href="https://pancakeswap.finance/swap?outputCurrency=0xb07905396A419B121213efe1d17cfD0ff20aE597" target="_blank">BUY</a></li>
                            <li><a href="/marketplace">MARKETPLACE</a></li>
                            <li><a href="/pizzanft">PIZZA NFT ECOSYSTEM</a></li>
                            <li><a href="#">STAKE</a></li>
                            <li><a href="/home/#roadmap">ROADMAP</a></li>
                            <li><a href="/documents-audits">DOCUMENTS</a></li>
                            <li><a href="/team">TEAM</a></li>
                            <li><a href="/home/#contactus">CONTACT</a></li>
                            <li><a href="/media">PR MEDIA</a></li>
                        </ul>
                    </div>


                    <div className="mobile-nav">
                        <nav class="navbar navbar-inverse">
                            <div class="navbar-header">
                                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                                <div className="mobile-logo-container">
                                    <a href="/" target="_blank"><img src={Logo} alt="" /></a>
                                </div>
                            </div>
                            <div class="collapse navbar-collapse" id="myNavbar">
                                <ul class="nav navbar-nav">
                                    <li><a href="https://pancakeswap.finance/swap?outputCurrency=0xb07905396A419B121213efe1d17cfD0ff20aE597" target="_blank">BUY</a></li>
                                    <li><a href="/marketplace">MARKETPLACE</a></li>
                                    <li><a href="/pizzanft">PIZZA NFT ECOSYSTEM</a></li>
                                    <li><a href="#">STAKE</a></li>
                                    <li><a href="/home/#roadmap">ROADMAP</a></li>
                                    <li><a href="/documents-audits">DOCUMENTS</a></li>
                                    <li><a href="/team">TEAM</a></li>
                                    <li><a href="/home/#contactus">CONTACT</a></li>
                                    <li><a href="/media">PR MEDIA</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar
