import React, { useEffect, useState } from 'react'

import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import MainImages from '../components/mainimages/MainImages'
import StarParalax from '../components/starparalax/StarParalax'
import TimeLine from '../components/timeline/TimeLine'
import ContactUs from '../components/contactus/ContactUs'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'
import MailChimp from '../components/mailchimp/MailChimp'
import ButtonSet from '../components/buttonset/ButtonSet'
import SocialBar from '../components/socialbar/SocialBar'

import NftImage from '../assets/nft.svg'
import DeviderSecond from '../assets/path-2.svg'


const PizzaNft = () => {
    return (
        <>

            <SocialLinks />
            <NavBar />
            <SocialBar />
            <div className="container">
                <div className="nftimage-container">
                    <img src={NftImage} alt="" />
                </div>
            </div>
            <Footer />
            <ScrollTop />

        </>
    )
}

export default PizzaNft
